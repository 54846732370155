html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}


ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* End of Eric Meyer's CSS Reset */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

p {
	margin: 0
}

input,
button,
select,
textarea,
a {
	outline: 0 !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

button {
	border: none;
	background-color: transparent;
	padding: 0;
	font-family: inherit;
}

img {
	max-width: 100%;
	height: auto
}

svg,
img {
	vertical-align: bottom;
}

a {
	display: inline-block;
	text-decoration: none;
}

@font-face {
	font-family: 'MTSWideMedium';
	src: url('fonts/wide/MTSWide-Medium/MTSWide-Medium.eot');
	src:
		url('fonts/wide/MTSWide-Medium/MTSWide-Medium.eot?#iefix') format('embedded-opentype'),
		url('fonts/wide/MTSWide-Medium/MTSWide-Medium.woff2') format('woff2'),
		url('fonts/wide/MTSWide-Medium/MTSWide-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MTSCompactBold';
	src: url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.eot');
	src:
		url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.woff2') format('woff2'),
		url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MTSCompactMedium';
	src: url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.eot');
	src:
		url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.eot?#iefix') format('embedded-opentype'),
		url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.woff2') format('woff2'),
		url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MTSCompactRegular';
	src: url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.eot');
	src:
		url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.woff2') format('woff2'),
		url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


* {
	--bg-primary: #fff;
	--bg-tetriary: #284059;
	--bg-accent: #f4b30c;
	--bg-accent-hover: #d79e0d;
	--input-border: #e2e5eb;
	--text-inverted: #fff;
	--text-form-primary: #001424;
	--text-form-secondary: #122840;
	--text-tetriary: #93a8bf;
	--text-link: #41adef;
	--warn: #e06400;
	--web-secondary-card-bg: #001a32;
	--web-primary-bright-blue: #45b7fc;
	--web-primary-blue: #001424;
	--web-tetriary-blue: #284059;
	--web-primary-gray: #677f99;
	--web-secondary-gray: #849ab2;
	--web-primary-blue-rgba: 0, 20, 36;
	--web-modal-bg-gradient: linear-gradient(180.52deg, #0e2339 0.45%, #132f49 38.74%);
	--shadow: 0px 4px 24px rgba(0, 0, 0, 0.12), 0px 8px 16px rgba(0, 0, 0, 0.08);
	--shadow-height: 0px 4px 70px rgba(0, 0, 0, 0.3), 0px 12px 20px rgba(0, 0, 0, 0.14);
	--control-surgut: linear-gradient(180deg, #3c73b9, #384F9E);
	--control-rostov: #ffffff26;
	--control-tver: #ffffff1a;
	--text-vladimir: #ffffff;
	--text-volgograd: #fafafa;
}


body {
	width: 100%;
	background: #040518;
	color: black;
	font-size: 20px;
	line-height: 28px;
	color: white;
	font-family: 'MTSCompactRegular';
	overflow-x: hidden;
}

#root {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.wrapper {
	max-width: 1552px;
	width: 100%;
	padding: 0 32px;
	margin: 0 auto;
}

.header {
	padding: 32px 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.header .wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.f_screen {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 25px;

	@media (max-width: 767px) {
		padding: 0 10px;
		width: 100%;
		overflow: hidden;
	}

	.text {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 12px;
		margin-top: 92px;

		.desc {
			font-size: 20px;
			line-height: 28px;
			font-weight: 400;
			text-align: center;

			@media (max-width: 1199px) {
				font-size: 17px;
				font-weight: 400;
				line-height: 22px;
				text-align: center;
				max-width: 604px;
				color: #E6E6E8;
			}

			@media (max-width: 767px) {
				font-size: 14px;
				line-height: 18px;
			}
		}
	}

	img {
		@media (max-width: 767px) {
			height: 209px;
			min-width: 784px;
			flex-shrink: 0;
		}
	}

	.arrow {
		position: fixed;
		z-index: 2;
		bottom: 4%;
		left: 50%;
		right: 50%;
		height: 30px;
		width: 30px;
		border-right: 1px solid white;
		border-bottom: 1px solid white;
		transform: rotate(45deg);
		animation: 1s arrow infinite;

		@media (max-width: 1199px) {
			display: none;
		}

		@keyframes arrow {
			0% {
				margin-bottom: 10px;
			}

			33% {
				margin-bottom: 0px;
			}

			100% {
				margin-bottom: 10px;
			}
		}
	}
}

.main-content {
	position: relative;
	z-index: 3;
	display: flex;
	flex-direction: column;
	align-items: center;

	max-width: 1104px;
	width: 100%;

	@media (max-width: 1199px) {
		padding: 0 24px;
	}

	>* {
		width: 100%;
		padding-bottom: 82px;

		@media (max-width: 1199px) {
			padding-bottom: 62px;
		}
	}
}

.btn {
	cursor: pointer;
	z-index: 7;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	text-transform: uppercase;
	color: white;
	line-height: 16px;
	font-weight: 500;
	font-family: 'MTSWideMedium';
	position: relative;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 0 12px;

	&:before {
		z-index: -1;
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		border-radius: 16px;

		-webkit-transition-duration: 0.5s;
		-o-transition-duration: 0.5s;
		-moz-transition-duration: 0.5s;
		transition-duration: 0.5s;

		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: linear-gradient(180deg, #8E4383 0%, #95407A 45.3%, #922D60 45.31%, #B92E2D 100%),
			linear-gradient(270deg, rgba(180, 46, 45, 0.62) 10.94%, rgba(172, 59, 89, 0) 100%);
	}

	&:hover {
		-webkit-box-shadow: 4px 4px 20px 0px #3A298580;
		box-shadow: 4px 4px 20px 0px #3A298580;
		-webkit-box-shadow: 0px 4px 4px 0px #00000040;
		box-shadow: 0px 4px 4px 0px #00000040;
	}

	&:after {
		z-index: -2;
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		border-radius: 16px;

		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: -o-linear-gradient(163.73deg, #3973B9 -55.43%, #355DA8 -42.18%, #334093 -22.27%, #303189 -6.93%, #2E2B86 1.87%, #C40D71 62.99%, #F01A5D 90%);
		background: linear-gradient(286.27deg, #3973B9 -55.43%, #355DA8 -42.18%, #334093 -22.27%, #303189 -6.93%, #2E2B86 1.87%, #C40D71 62.99%, #F01A5D 90%);
	}

	&:hover:before {
		opacity: 0;
	}

	@media (max-width: 1199px) {
		font-size: 12px;
		line-height: 16px;
	}

	&.big-btn {
		font-size: 12px;
		line-height: 16px;
		border-radius: 4px;
		padding: 18px;

		@media (max-width: 1199px) {
			padding: 10px;
		}
	}
}

.disabled-button {
	pointer-events: none;
	opacity: 0.4;
}

h1 {
	font-size: 44px;
	line-height: 54px;
	font-weight: 500;
	font-family: 'MTSWideMedium';
	text-align: center;

	@media (max-width: 1199px) {
		font-size: 24px;
		line-height: 30px;
		max-width: 100%;
		max-width: 368px;
	}

	@media (max-width: 767px) {
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 11px;
	}
}

h2 {
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
	font-family: 'MTSWideMedium';
	text-align: left;
	width: 100%;

	@media (max-width: 1199px) {
		font-size: 24px;
		line-height: 30px;
	}

	@media (max-width: 767px) {
		font-size: 17px;
		line-height: 22px;
		text-align: center;
	}
}

h2.title {
	margin-bottom: 16px;
}

h3 {
	font-family: 'MTSWideMedium';
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;

	@media (max-width: 1199px) {
		font-size: 20px;
		line-height: 26px;
	}

	@media (max-width: 767px) {
		font-size: 11px;
		line-height: 18px;
	}
}

.swiper-block {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;

	h2 {
		max-width: 663px;
	}

	.images {
		width: 100vw;
		position: relative;
		left: 50%;
    	transform: translate(-50%);
		margin-top: 40px;

		@media (max-width: 1199px) {
			margin-top: 14px;
		}

		@media (max-width: 767px) {
			.images {
				margin-top: 6px;
			}
		}

		.images-content {
			margin-left: -8.888%;
			width: 116.6666%;
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 1199px) {
				margin-left: -6%;
			}
		}
	}
}

.economy_block {
	display: flex;
	flex-direction: column;
	gap: 82px;

	@media (max-width: 1199px) {
		gap: 62px;
	}

	.tariffs {
		display: flex;
		flex-direction: column;
		gap: 40px;

		@media (max-width: 767px) {
			gap: 16px;
		}
	}

	.tariff_block {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 16px;
		overflow-x: auto;

		@media (max-width: 767px) {
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
		}

		.tariff {
			border-radius: 24px;
			padding: 54px 24px;
			background: rgba(17, 35, 66, 0.3);
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 12px;

			@media (max-width: 1199px) {
				padding: 35px 24px;
			}
			
			@media (max-width: 767px) {
				padding: 24px;
			}

			.period {
				font-size: 24px;
				line-height: 30px;
				font-weight: 500;
				font-family: 'MTSWideMedium';
				color: rgba(250, 250, 250, 0.6);

				@media (max-width: 1199px) {
					font-size: 20px;
					line-height: 26px;
				}

				@media (max-width: 767px) {
					font-size: 14px;
					line-height: 18px;
				}
			}

			.price_block {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				gap: 4px;
				flex-wrap: wrap;

				.discount_price {
					font-size: 64px;
					line-height: 78px;
					font-weight: 500;
					font-family: 'MTSWideMedium';

					@media (max-width: 1199px) {
						font-size: 48px;
						line-height: 68px;
					}

					@media (max-width: 767px) {
						font-size: 32px;
						line-height: 40px;
					}
				}

				.full_price {
					display: table;
					position: relative;
					font-size: 24px;
					line-height: 30px;
					font-weight: 500;
					font-family: 'MTSWideMedium';
					color: rgba(250, 250, 250, 0.6);

					@media (max-width: 1199px) {
						font-size: 20px;
						line-height: 26px;
					}

					@media (max-width: 767px) {
						font-size: 20px;
						line-height: 26px;
					}
				}

				.full_price:before {
					position: absolute;
					content: '';
					background: #FF0032;
					width: 78px;
					height: 3px;
					top: 60%;
					left: 50%;

					-webkit-transform: translate(-50%, -50%) rotate(166.5deg);
					-ms-transform: translate(-50%, -50%) rotate(166.5deg);
					transform: translate(-50%, -50%) rotate(166.5deg);
				}
			}

			.btn {
				width: 139px;
				height: 44px;
			}
		}
	}

	.new_items_block {
		background: rgba(17, 35, 66, 0.3);
		border-radius: 21px;
		padding: 24px 12px;
		display: flex;
		flex-direction: column;
		gap: 11px;
		overflow: hidden;

		@media (max-width: 767px) {
			display: none;
		}

		h3 {
			margin-left: 12px;
		}

		.new_items {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;
			gap: 19px;
			height: 268px;

			@media (max-width: 1199px) {}

			.image_container {
				position: relative;
				width: auto;
				height: 222px;
				width: 159px;
				transition: transform 0.3s ease, border 0.3s ease;

				@media (max-width: 1199px) {
					&:nth-child(n+5) {
						display: none;
					}
				}

				&:hover {
					transform: scale(1.2);
					border-radius: 11px 11px 0 0;
					padding: 2.59px 2.59px 0 2.59px;
					background: linear-gradient(45deg, #E6012A, #E6012A, #BA1D7A, #6B14D7);
					bottom: 7%;

					img {
						border-radius: 7px 7px 0 0;
					}
				}

				img {
					width: 100%;
					height: 100%;
					border-radius: 5.35px 5.35px 0 0;
					border: 1px solid #080e25;
				}
			}
		}
	}

	.tiles {
		display: flex;
		flex-direction: row;
		gap: 28px;
		height: 447px;
		overflow-x: auto;

		@media (max-width: 1199px) {
			height: 408px;
		}

		@media (max-width: 767px) {
			height: 287px;
			margin-left: -20px;
			margin-right: -20px;
			padding-left: 20px;
		}

		.big_tile {
			display: flex;
			flex-direction: column;
			position: relative;
			background: rgba(17, 35, 66, 0.3);
			border-radius: 21px;
			padding: 24px 26px;
			width: 50%;
			position: relative;
			overflow: hidden;
			height: 100%;
			flex-shrink: 0;

			@media (max-width: 767px) {
				width: 240px;
			}

			&::before {
				content: '';
				background: radial-gradient(111.99% 86.9% at 104.54% 0%, #E7012B 4.49%, #A30A64 52.42%, #5615a426 100%)
					/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
				;
				position: absolute;
				width: 600px;
				height: 600px;
				top: 0;
				left: 0;
				z-index: -1;
				transform: rotate(90deg);
				opacity: 0.7;
			}

			h3 {
				max-width: 488px;
			}

			.img1 {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -35%);

				@media (max-width: 1199px) {
					top: 50%;
					left: 50%;
					transform: translate(-50%, -35%);
				}

				@media (max-width: 767px) {
					top: 57%;
					scale: 1.2;
				}
			}
		}

		.right_tiles {
			display: flex;
			flex-direction: column;
			gap: 28px;
			width: 50%;

			@media (max-width: 767px) {
				flex-direction: row;
			}

			.small_tile {
				display: flex;
				flex-direction: column;
				position: relative;
				background: rgba(17, 35, 66, 0.3);
				border-radius: 21px;
				padding: 21px;
				overflow: hidden;
				height: 100%;

				@media (max-width: 767px) {
					width: 240px;
					flex-shrink: 0;
				}

				&::before {
					content: '';
					background: radial-gradient(111.99% 86.9% at 104.54% 0%, #E7012B 4.49%, #A30A64 52.42%, #5615a426 100%)
						/* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
					;
					position: absolute;
					width: 530px;
					height: 530px;
					top: -130%;
					left: 0;
					z-index: -1;
					transform: rotate(90deg);
					opacity: 0.7;

					@media (max-width: 767px) {
						width: 150%;
						height: 200%;
						top: -60%;
					}
				}

				h3 {
					font-size: 21px;
					line-height: 26px;
				}

				.img2 {
					position: absolute;
					top: 31%;
					left: 34%;

					@media (max-width: 1199px) {
						top: 32%;
						left: 0;
					}

					@media (max-width: 767px) {
						top: 32%;
						left: 14px;
						scale: 1.2;
					}
				}

				.img3 {
					position: absolute;
					top: 25%;
					left: 46%;

					@media (max-width: 1199px) {
						top: 50%;
						left: 50%;
						transform: translate(-20%, -35%);
						scale: 0.8;
					}

					@media (max-width: 767px) {
						top: 50%;
						left: 50%;
						transform: translate(-50%, -35%);
						scale: 0.9;
					}
				}
			}
		}
	}
}

.add_features_block {
	display: flex;
	flex-direction: column;
	gap: 40px;

	h2 {
		width: 100%;
	}

	.features {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 16px;
		row-gap: 16px;

		@media (max-width: 767px) {
			align-items: center;
			flex-direction: column;
			grid-template-columns: 1fr;
		}

		.item_feature {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 24px;
			max-width: 544px;
			width: 100%;
			background: rgba(17, 35, 66, 0.3);
			box-shadow:
				0px 4px 16px 0px rgba(0, 0, 0, 0.08),
				0px 0px 6px 0px rgba(0, 0, 0, 0.8);
			border-radius: 24px;

			@media (max-width: 767px) {
				box-shadow: unset;
				background-color: unset;
				padding: unset;
				max-width: unset;
			}

			.left_block {
				display: flex;
				flex-direction: row;
				align-items: center;

				img {
					margin-right: 16px;

					@media (max-width: 1199px) {
						width: 32px;
						height: 32px;
					}

					@media (max-width: 767px) {
						width: 44px;
						height: 44px;
						margin-right: 12px;
					}
				}

				.info {
					display: flex;
					flex-direction: column;
					justify-content: center;
					max-width: 308px;

					.mobile-visible {
						display: none;
					}

					@media (max-width: 1199px) {
						.mobile-hidden {
							display: none;
						}

						.mobile-visible {
							display: unset;
						}
					}

					.name {
						font-weight: 400;
						font-size: 20px;
						line-height: 28px;
						font-family: 'MTSCompactMedium';
						text-transform: uppercase;
						color: #626C77;

						@media (max-width: 767px) {
							font-size: 12px;
							line-height: 16px;
						}
					}

					.desc {
						font-weight: 500;
						font-size: 24px;
						line-height: 30px;
						font-family: 'MTSCompactMedium';
						text-align: left;

						@media (max-width: 1199px) {
							font-size: 17px;
							line-height: 22px;
						}

						@media (max-width: 767px) {
							font-size: 14px;
							line-height: 18px;
						}
					}
				}
			}

			.price {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;
				min-width: 80px;

				@media (max-width: 767px) {
					min-width: 47px;
				}

				.discount_price {
					font-weight: 500;
					font-size: 24px;
					line-height: 30px;
					font-family: 'MTSCompactMedium';
					color: #FAFAFA;

					@media (max-width: 1199px) {
						font-size: 20px;
						line-height: 28px;
					}

					@media (max-width: 767px) {
						font-size: 14px;
						line-height: 18px;
					}
				}

				.full_price {
					font-weight: 400;
					font-size: 20px;
					line-height: 28px;
					color: #626C77;
					text-decoration: line-through;

					@media (max-width: 767px) {
						font-size: 12px;
						line-height: 16px;
					}
				}
			}
		}
	}
}

.footer {
	color: #677F99;
	font-weight: 400;
	font-size: 14px;
	font-family: 'MTSCompactMedium';
	line-height: 18px;
	padding: 44px 0;

	.text {
		a {
			color: #677F99;
		}

		b {
			font-weight: bold;
		}
	}
}

.footer-link {
	font-weight: bold;
}

.sample-slider .swiper-wrapper {
	transition-timing-function: linear;
}

.img-block {
	padding: 7px;
	width: 12% !important;

	a {
		height: 100%;
		width: 100%;
	}
}

.swiper-wrapper {
	transition-timing-function: linear;
}

.img-block img {
	width: 100%;
	height: 100%;
	-webkit-border-radius: 24px;
	-moz-border-radius: 24px;
	border-radius: 24px;
}

@media (max-width: 1199px) {
	.wrapper {
		padding: 0 24px;
	}

	.header {
		padding: 28px 0
	}

	.header svg {
		height: 36px;
		width: auto
	}

	.img-block {
		padding: 4px;
	}

	.img-block img {
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		border-radius: 20px;
	}
}

@media (max-width: 767px) {
	.wrapper {
		width: 100%;
		padding: 0 20px;
	}

	.header {
		padding: 16px 0;
	}

	.header svg {
		height: 29px;
	}

	.grey-block {
		padding: 8px 0 34px;
	}

	.img-block {
		padding: 2px;
	}
}